import { useTranslate } from '/machinery/I18n'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'

import { HeadingLg } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Matchmaker.css'

import thumbIcon from '/images/icons/thumb.raw.svg'

export function Matchmaker({ matchmaker }) {
  const { __ } = useTranslate()
  const [showPlusPoints, setShowPlusPoints] = React.useState(true)

  return (
    <section data-x='matchmaker' className={styles.component}>
      <div className={styles.bigButtonContainer}>
        <HeadingLg h='2'>{__`do-you-match-with-us`}</HeadingLg>
        <BigButton onChange={handleClick} checked={!showPlusPoints} />
      </div>
      <div className={styles.pointsContainer}>
        <PointsList points={matchmaker.plusPoints} active={showPlusPoints} />
        <PointsList points={matchmaker.minusPoints} active={!showPlusPoints} />
      </div>
    </section>
  )

  function handleClick() {
    setShowPlusPoints(!showPlusPoints)

    trackInteraction({
      title: 'matchmaker-toggle',
      action: 'click',
      type: 'toggle',
    })
  }
}

function BigButton({ onChange, checked }) {
  return (
    <div className={cx(styles.componentBigButton, checked && styles.checked)}>
      <span className={cx(styles.checkboxSlider, checked && styles.checked)}>
        <span className={cx(styles.iconContainer, checked && styles.checked)}>
          <Icon icon={thumbIcon} />
        </span>
      </span>
      <input type='checkbox' className={styles.input} {...{ onChange, checked }} />
    </div>
  )
}

function PointsList({ points, active }) {
  return (
    <div className={styles.componentPointsList}>
      {points.map((item, i) => (
        <div key={i} style={{ '--index': i + 1 }} className={cx(styles.pointContainer, active && styles.active)}>
          <Point {...{ item }} />
        </div>
      ))}
    </div>
  )
}

function Point({ item }) {
  return (
    <div className={styles.componentPoint}>
      <h3 className={styles.pointTitle}>{item.title}</h3>
      <p className={styles.pointValue}>{item.point}</p>
    </div>
  )
}
