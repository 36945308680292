import { Video } from '/features/buildingBlocks/Video'

import styles from './VideoInline.css'

export function VideoInline({ title, url, poster }) {
  return (
    <section data-x='inline-video' className={styles.component}>
      <Video {...{ url, title, poster }} />
    </section>
  )
}
