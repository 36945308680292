import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'

import { ContainerLg } from '/features/buildingBlocks/Container'
import { HeadingSm } from '/features/buildingBlocks/Heading'
import { SliderButtons } from '/features/buildingBlocks/SliderButtons'
import { MediaWindow } from '/features/pageOnly/MediaWindow'

import styles from './TeamMembers.css'
import 'keen-slider/keen-slider.min.css'

export function TeamMembers({ title, introduction, images }) {
  const isMounted = useRenderOnMount()

  const initialSlide = 1
  const loop = true
  const [currentSlide, setCurrentSlide] = React.useState(initialSlide)
  const animation = { duration: 25000, easing: (t) => t }

  const { sliderRef, instanceRef } = useKeenSliderRefs({
    handleSlideChange,
    slidesNum: images?.length,
    slidesPerView: 'auto',
    spacing: 16,
    initialSlide,
    sliderDisabled: false,
    origin: 'center',
    loop,
    interactionTitle: 'team-members-slider',
    created(s) {
      s.moveToIdx(5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
  })

  return (
    <section data-x='team-members-slider' className={styles.component}>
      <div ref={sliderRef} className={cx(styles.slider, 'keen-slider')}>
        {images?.map((image, i) => (
          <div key={i} className={cx(styles.slide, 'keen-slider__slide')}>
            {<ImageSlide layoutClassName={styles.slideLayout} {...{ image }} />}
          </div>
        ))}
      </div>
      <ContainerLg>
        <div className={styles.bottomContainer}>
          {isMounted && (
            <SliderButtons {...{ currentSlide, loop, handleClick }} />
          )}
          <div className={styles.headingContainer}>
            <span className={styles.line} />
            {title && <HeadingSm h='3' layoutClassName={styles.headingLayout}>{title}</HeadingSm>}
            {introduction &&  <p className={styles.introduction}>{introduction}</p>}
          </div>
        </div>
      </ContainerLg>
    </section>
  )

  function handleClick(idx) {
    instanceRef.current?.moveToIdx(idx)
  }

  function handleSlideChange(idx) {
    setCurrentSlide(idx)
  }
}

function ImageSlide({ image, layoutClassName = undefined }) {
  return <MediaWindow aspectRatio={5 / 7} distanceOverride={30} {...{ image, layoutClassName }} />
}
