import { useReportError } from '/machinery/ReportError'
import { useWithGroupedBlocksType } from '/machinery/useWithGroupedBlocksType'

import { ContainerMd, ContainerLg, ContainerXl } from '/features/buildingBlocks/Container'
import { PortableTextComponent } from '/features/buildingBlocks/PortableTextComponent'
import { BlockWithTitleAndLine } from '/features/pageOnly/BlockWithTitleAndLine'
import { BlockWithImages } from '/features/pageOnly/BlockWithImages'
import { LargeImage } from '/features/pageOnly/LargeImage'
import { VideoInline } from '/features/pageOnly/VideoInline'
import { QuoteWithPortrait } from '/features/pageOnly/QuoteWithPortrait'
import { Matchmaker } from '/features/pageOnly/Matchmaker'
import { ContentSlider } from '/features/pageOnly/ContentSlider'
import { ContentCards } from '/features/pageOnly/ContentCards'
import { TeamMembers } from '/features/pageOnly/TeamMembers'
import { VacanciesList } from '/features/pageOnly/VacanciesList'

export function ContentWithoutUniversalComponents({ content }) {
  const contentWithGroupedBlocks = useWithGroupedBlocksType(content ?? [])
  const reportError = useReportError()

  return contentWithGroupedBlocks.map((x, i) => {
    switch (x._type) {
      case 'blocks': return <ContainerMd key={i}><PortableTextComponent value={x.blocks} /></ContainerMd>
      case 'blockWithTitleAndLine': return <ContainerXl key={i}><BlockWithTitleAndLine title={x.title} text={x.content} /></ContainerXl>
      case 'blockWithImages': return <ContainerXl key={i}><BlockWithImages title={x.title} text={x.block} cta={x.cta} images={x.images} /></ContainerXl>
      case 'image': return <ContainerLg key={i}><LargeImage image={x} /></ContainerLg>
      case 'video': return <ContainerXl key={i}><VideoInline title={x.title} url={x.src} poster={x.poster} /></ContainerXl>
      case 'quoteWithPortrait': return <ContainerXl key={i}><QuoteWithPortrait quote={x.quote} source={x.source} portrait={x.portrait} imageAlignment={x.imageAlignment} /></ContainerXl>
      case 'matchmaker': return <ContainerLg key={i}><Matchmaker matchmaker={x} /></ContainerLg>
      case 'contentSlider': return <ContentSlider key={i} items={x.items} />
      case 'contentCards': return <ContainerXl key={i}><ContentCards items={x.cards} /></ContainerXl>
      case 'teamMembers': return <TeamMembers key={i} title={x.title} introduction={x.introduction} images={x.images} />
      case 'jobList': return x.jobs?.length ? <VacanciesList key={i} jobs={x.jobs} title={x.title} button={x.button} /> : null
      default: {
        reportError(new Error(`Unknown content type '${x._type}'`))
        return null
      }
    }
  })
}
