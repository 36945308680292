import styles from './ContentWrapper.css'

export function ContentWrapper({ children }) {
  return <ContentWrapperBase className={styles.component} {...{ children }} />
}

export function ContentWrapperWithoutBottomPadding({ children }) {
  return <ContentWrapperBase className={styles.componentWithoutBottomPadding} {...{ children }} />
}

function ContentWrapperBase({ children, className }) {
  return (
    <section data-x='content-section' className={cx(styles.componentBase, className)}>
      {children}
    </section>
  )
}
