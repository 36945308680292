import { PortableText } from '@portabletext/react'
import { animated, useSpring } from '@react-spring/web'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'
import { lerp } from '@kaliber/math'

import { MediaWindow } from '/features/pageOnly/MediaWindow'

import styles from './QuoteWithPortrait.css'

export function QuoteWithPortrait({ quote, source, portrait, imageAlignment = undefined }) {
  return (
    <section data-x='quote-with-portrait' className={cx(styles.component, imageAlignment === 'left' && styles.imageAlignmentLeft)}>
      <BlockQuote layoutClassName={styles.blockQuoteLayout} {...{ quote, source }} />
      <MediaWindow image={portrait} aspectRatio={2 / 3} distanceOverride={45} layoutClassName={styles.mediaWindowLayout} />
    </section>
  )
}

function BlockQuote({ quote, source, layoutClassName = undefined }) {
  const [style, api] = useSpring(() => ({
    '--progress': '0%',
    '--opacity': '0',
    config: { tension: 300, friction: 40 }
  }))

  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.custom(0.66) },
    end: { element: triggers.bottom(), scrollParent: triggers.custom(0.75) },
    onChange(progression) {
      api.start({
        '--progress': `${lerp({ start: 0, end: 100, input: easeOut(progression) })}%`,
        '--opacity': `${lerp({ start: 0, end: 100, input: easeOut(Math.max(Math.min(progression * 100, 1), 0.1)) })}%`
      })
    }
  })

  /** @type {import('@portabletext/react').PortableTextProps['components']} */
  const components = {
    block: {
      normal: ({ children }) => <><span className={source && styles.quoteParagraph}>{children}</span><br /><br /></>,
    }
  }

  return (
    <div className={cx(styles.componentBlockQuote, layoutClassName)} {...{ ref }}>
      {/* @ts-ignore */}
      <animated.q className={styles.quoteHighlightElement} {...{ style }}>
        <PortableText value={quote} {...{ components }} />
      </animated.q>
      {source && <cite className={styles.source}>{source}</cite>}
    </div>
  )
}

function easeOut(x) {
  return Math.sin((x * Math.PI) / 2)
}
